
const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

const uniqueId = () => {
    return s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + '-' + s4() + '-' + s4();
}

// A few JavaScript Functions for Images and Files
// Author: Justin Mitchel
// Source: https://kirr.co/ndywes

// Convert a Base64-encoded string to a File object
export function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}

// Download a Base64-encoded file

export function downloadBase64File(base64Data, filename) {
    var element = document.createElement('a')
    element.setAttribute('href', base64Data)
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
    return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
}

// Base64 Image to Canvas with a Crop
export function image64toCanvasRef(canvasRef, image64, pixelCrop) {
    console.log(pixelCrop.width)
    const canvas = canvasRef // document.createElement('canvas');

    const image = new Image()
    image.src = image64
    const targetX = image.width * pixelCrop.x / 100;
    const targetY = image.height * pixelCrop.y / 100;
    const targetWidth = image.width * pixelCrop.width / 100;
    const targetHeight = image.height * pixelCrop.height / 100;

    canvas.width = targetWidth
    canvas.height = targetHeight
    // console.log(canvas.width)
    const ctx = canvas.getContext('2d')

    image.onload = function () {
        ctx.drawImage(
            image,
            targetX,
            targetY,
            targetWidth,
            targetHeight,
            0,
            0,
            targetWidth,
            targetHeight
        )
    }
}

export { uniqueId }