import React from 'react';
import ShowApartment from './com/showApartment';

const ApartmentRent = () => {

    return <ShowApartment type={'forRent'} />

}

export default ApartmentRent;
